li {
  text-decoration: none;
  list-style-type: none;
}

a {
  color: #000;
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.btn-list {
  display: flex;
}

.btn-list li {
  margin-left: 1rem;
}

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 6px;
  border: 1px line #000;
  background-color: #888;
  color: #fff;
  transition: all 0.2s;
}

.btn:hover {
  opacity: 0.8;
}

.btn-primary {
  background-color: #0b5ed7;
}

.btn-exit {
  background-color: #bb2d3b;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .login {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 60%;
  background-color: #ccc;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.form input {
  height: 44px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  padding: 0 8px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.form label {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
} */

.form {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.btn-submit {
  border: none;
  user-select: none;
  outline: none;
  background: transparent;
  padding: 0;
  display: block;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* margin-top: 10px; */
  height: 54px;
  width: 100%;
  border-radius: 5px;
  background-color: #00a4dd;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}

.btn-logout {
  color: #f52a49;
  background: #ffffff;
  border: 1px solid #f52a49;
  margin: 0 30px 0 0 !important;
}

.btn-submit:hover {
  opacity: 0.8;
}

.login-card {
  width: 90%;
  background: #f8f8f8;
  border-radius: 25px;
  margin: 30px 0;
  min-height: 84vh;
}

.bodyTop {
  width: 100%;
  height: 25%;
  min-height: 16vh;
}

.logoButton {
  display: inline-block;
  margin: 45px 0 0 100px;
}

.login-card + .login-card {
  padding-top: 10px;
}

.login-card h1 {
  font-weight: 100;
  text-align: center;
  font-size: 2.3em;
}

.login-card button {
  width: 200px;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  position: relative;
}

/* .login-card input {
  height: 44px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  padding: 0 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
} */

.login {
  text-align: center;
  font-size: 14px;
  font-family: "Arial", sans-serif;
  font-weight: 700;
  height: 36px;
  padding: 0 8px;
}

.login-submit {
  border: 0px;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.1);
  background-color: #4d90fe;
}

.login-card a {
  text-decoration: none;
  color: #666;
  font-weight: 400;
  text-align: center;
  display: inline-block;
}

.login-help {
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.login-client-image img {
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

.login-card input[type="checkbox"] {
  margin-bottom: 10px;
}

.login-card select {
  max-width: 447px;
}

.body-left select {
  max-width: 447px;
}

.login-card label {
  color: #999;
  font-family: Arial, sans-serif;
}

@media screen and (max-width: 800px) {
  .login-card label {
    margin-left: -25px;
    color: #999;
    font-family: Arial, sans-serif;
  }
}

.login-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  align-items: center;
  justify-content: center;
}

/* STYLE FOR HEADER */
.header {
  padding: 0px 20px;
}

.header .btn-list {
  padding: 0px;
}

.header .btn-list li:first-child {
  margin-left: 0px;
}

.header .btn-list li .btn {
  padding: 5px;
  min-width: 100px;
  text-align: center;
}

.info-user {
  padding: 0px 20px;
}

.list-info-user .content {
  border: 1px solid #1b1a1a;
  background-color: #dfd8d8;
  padding: 5px;
  word-break: break-all;
}
.list-info-user .content pre {
  white-space: break-spaces;
}

.container {
  display: flex;
}

.ctn-left {
  height: 75%;
  padding: 0 0 40px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  /* width: 50%; */
  width: 33%;
}

.ctn-right {
  height: 75%;
  padding: 0 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 50%; */
  width: 33%;

}

.input {
  width: 100%;
  margin: 10px 0 30px 0;
  max-width: 397px;
  height: 55px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 0 26px 0 20px;
  align-items: center;
  display: flex;
  outline: none;
  font-family: Fira Sans, sans-serif;
  background-color: #f1f1f1;
  color: #373737;
  font-size: 1.125rem;
  line-height: 19px;
  border: none;
  border-radius: 5px;
}

.titleOIDC {
  font-family: "Fira Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  /* identical to box height */

  text-align: center;

  color: #3b95d4;
  margin: 0 0 20px 0;
}

.label {
  width: 100%;
  max-width: 447px;
  text-align: left !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #2c2c2c !important;
}

@media (max-width: 400px) {
  .container_btn {
    width: 110%;
  }
}

button:disabled,
button[disabled] {
  cursor: not-allowed !important;
  opacity: 0.5;
}

@media screen and (max-width: 800px) {
  .titleOIDC {
    width: 70%;
    font-family: "Fira Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    color: #3b95d4;
  }
  .login-card {
    width: 90%;
    background-color: #ffffff;
    border-radius: 25px;
    margin: 30px 0;
    min-height: 84vh;
  }
  .container_btn {
    display: flex;
    flex-direction: column;
  }
  .bodyTop {
    width: 100%;
    height: 20%;
    min-height: 10vh;
    display: flex;
    justify-content: center;
  }
  .logoButton {
    margin: 0 0 0 0;
  }
  .btn-submit {
    width: 300px !important;
    margin: 0 auto !important;
    display: block;
    margin-bottom: 10px !important;
    position: relative;
    cursor: none;
  }
  .input {
    padding: 0 5px 0 20px;
  }
  .select {
    width: 110% !important;
  }
  .form {
    width: 80%;
  }
}

.ctn_bottom {
  width: 100%;
  display: flex;
}

.body-left {
  width: 50%;
}

.body-right {
  width: 50%;
}
